<template>
  <b-img
    fluid
    :src="((full===true) ? logoFull : logo)"
    :alt="$t('Logo')"
    :style="{height: height}"
  />
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  props: {
    height: {
      type: String,
      default: '',
      required: false,
    },
    full: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      logo: require('@/assets/images/zome/logo.png'),
      logoFull: require('@/assets/images/zome/logo_full.png'),
    }
  },
}
</script>
