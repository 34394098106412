<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <logo
        :height="`35px`"
        :full="true"
      />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t('Acesso negado') }}
        </h2>
        <p class="mb-2">
          {{ $t('Não tem permissões para aceder a esta página.') }}
        </p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          :to="{path:'/'}"
        >{{ $t('Voltar à página inicial') }}</b-button>
        <b-img
          fluid
          :src="imgUrl"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton } from 'bootstrap-vue'
import logo from '@core-custom/layouts/components/Logo.vue'
import store from '@/store/index'

export default {
  components: {
    BLink, BImg, BButton, logo,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
